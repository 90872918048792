import "./App.css";
import React, { useEffect, useState } from "react";
import widePutin from "./sounds/wideputin.mp3";
import sadviolin from "./sounds/sadviolin.mp3";
import WeGotHim from "./sounds/WeGotHim.mp3";
import whatareyoudoing from "./sounds/whatareyoudoing.mp3";
import whatdadogdoin from "./sounds/whatdadogdoin.mp3";

import { Howl, Howler } from "howler";

const audios = [
  { sound: widePutin, label: "Wide-Putin" },
  { sound: sadviolin, label: "Sad-Violin" },
  { sound: WeGotHim, label: "We-Got-Him" },
  { sound: whatareyoudoing, label: "What-Are-You-Doin-In-My-Swamp" },
  { sound: whatdadogdoin, label: "What-Da-Dog-Doin" },
];
function App() {
  const playsound = (src) => {
    const sound = new Howl({ src });
    sound.play();
  };
  Howler.volume(1.0);
  const renderbtn = () => {
    return audios.map((soundObj, index) => {
      return (
        <>
          <button
            className="btn"
            key={index}
            onClick={() => playsound(soundObj.sound)}
          >
            {soundObj.label}
          </button>
        </>
      );
    });
  };
  const renderdl = () => {
    return audios.map((soundObj, index) => {
      return (
        <>
          <a className="button" href={soundObj.sound} download={soundObj.label}>
            Download {soundObj.label}
          </a>
        </>
      );
    });
  };
  return (
    <>
      <div className="app">
        <div className="part">{renderbtn()}</div>
        <div className="part">{renderdl()}</div>
      </div>
    </>
  );
}

export default App;
